import React from 'react' 
import ContactWithUs from '../Components/ContactWithUs'
import NavBar from '../Components/NavBar'
import Navv from '../Components/Navv'
import ProjectBg from '../assets/images/backgroundProject.png'
import Meditation from '../assets/images/meditation.png'
import Get100 from '../assets/images/Get100marksimage.png'
import Allevi8 from '../assets/images/Allevi8neww.png'
import TelevisionAudienceMeasurement from '../assets/images/outlierDetection.jpg'
import iElection from '../assets/images/iElction.png'
import shoeSelector from '../assets/images/FootMeasurement.jpg'
import EightRate from '../assets/images/8Rate.png'

import ERP from '../assets/images/erp.png'
import ChatbottforNysodh from '../assets/images/ChatbottforNysodh.png'
import Bamboo from '../assets/images/Bamboo.jpg'
import PressureVesselsManagement from '../assets/images/PressureVesselsManagement.png'
import IdeaManagement from '../assets/images/IdeaManagement.jpg'
import Godrej from '../assets/images/Godrej.jpeg'
import ERG from '../assets/images/ERG.jpg'
import EDV from '../assets/images/EDVFeller.jpg'
import AirtelTV from '../assets/images/AirtelTV.png'
import CensusData from '../assets/images/CensusData.png'
import BAARCCluster from '../assets/images/ClusterFormation1.png'
import BARCHousehold from '../assets/images/Householddatacollection.png'
import BellyBaloo from '../assets/images/bellyBallo.jpg'
import OurProject from "../Components/OurProject"
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Data = [
    {
        img: Get100,
        heading: 'Get 100 Marks',
        paragraph: 'Education/ Mobile Application',
        category: 'education',
        more: `The Get 100 Marks App is an educational platform created to help university students excel in their academic exams. Available on both Android and iOS, the app offers a comprehensive range of study materials, quizzes, and practice exams designed to simplify and enhance the learning process. One of the key challenges in developing this app was managing complex input data and designing a user interface that presents information clearly and intuitively.`,
        more1:`Through innovative design solutions, we created an interface that is both engaging and easy to navigate, helping students focus on their studies. The app also incorporates interactive features, such as progress tracking and personalized study recommendations, making it an essential tool for academic success. By providing students with an organized and visually appealing study platform, Get 100 Marks helps them confidently prepare for exams, ultimately supporting their academic growth and success.`
    },
    {
        img: ERP,
        heading: 'ERP',
        paragraph: 'Education/ Web Platform',
        category: 'education',
        more:`The ERP solution custom-built for Tata Institute of Social Sciences’ School of Vocational Education (TISS-SVE) has been meticulously designed to address the institution's specific needs. This project successfully tackled crucial administrative and academic issues, including the management of intricate fee structures and the coordination of dynamic academic programs.`,
        more1:`Notable components of this tailored system comprise a robust Fees Module for precise financial management, an advanced Examination Module for seamless assessment processes, and a Communication Module that fosters enhanced collaboration among students, faculty, and administrators. Furthermore, the Student Life Cycle Management Module supervises the complete student journey, from admission to graduation, thereby optimizing student support and operational efficiency at TISS-SVE.`
    },
    {
        img: ChatbottforNysodh,
        heading: ' Conversational Chatbot for Healtcare Industry',
        paragraph: 'Healthcare/ Web Platform',
        category: 'healthcare',
        more:` Intellection developed a chatbot to facilitate easy access to the Special Supplemental Nutrition Program by helping users navigate the WIC system. The chatbot collects essential user information such as pregnancy status, zip code, and household income level, connecting users to the appropriate office. This is especially beneficial for low-income families who may struggle to access these services. A key challenge was designing the chatbot for users with lower literacy or technological proficiency. To address this, Intellection ensured it used simple language and clear, direct responses.`,
        more1:`The minimalistic design made the chatbot user-friendly, even for those unfamiliar with advanced tech tools. Given the sensitive nature of the data, Intellection ensured full HIPAA compliance and developed the platform with a focus on data security. This streamlined solution improved WIC accessibility and efficiency, increasing engagement among users who might have struggled with the system. Additionally, it provided breast cancer patients with educational resources and guidance, making healthcare more accessible to vulnerable populations.`
    },
    {
        img: Bamboo,
        heading: 'Insurance for Everyone: Healthcare Ecosystem',
        paragraph: 'Healthcare/ Web Platform',
        category: 'healthcare',
        more:`Intellection's project in the healthcare space combined blockchain, machine learning, and data visualization to create a secure healthcare ecosystem. The goal was to securely store patient health data, run predictive analyses, and present the findings in an accessible format for users and healthcare providers. The project faced challenges, particularly in encouraging users to continuously share their health data. To address this, Intellection developed an engaging, intuitive UI/UX design with personalized features that allowed users to monitor their health metrics over time.`,
        objective1:`The blockchain integration ensured all health data was stored securely and immutably, addressing concerns about data integrity and trust. Intellection also developed machine learning models that predicted potential health issues based on user data, allowing proactive health management. The data visualization platform made the information accessible, offering visually appealing, easy-to-understand dashboards that helped users and healthcare providers make informed decisions. The project's success highlights Intellection's expertise in integrating advanced technologies like blockchain and machine learning into healthcare while maintaining a focus on user engagement and data privacy.`
    },
    {
        img: BellyBaloo,
        heading: 'BellyBaloo',
        paragraph: 'Healthcare/ Mobile Apps',
        category: 'healthcare',
        more:`Intellection developed a mobile app to enhance the prenatal experience by providing secure access to ultrasound images on mobile devices. The app meets the growing demand for convenient, secure access to medical images, particularly for expecting mothers. A key challenge was ensuring the secure storage and transfer of sensitive data, which Intellection addressed by implementing advanced encryption technologies. The app’s intuitive interface was designed to be user-friendly, especially for less tech-savvy users.`,
        more1:`It also allows sharing images with loved ones and integrates seamlessly with healthcare providers’ systems for real-time access. Compatible with both iOS and Android, the app has received positive feedback for its ease of use and secure data handling, showcasing Intellection’s ability to create user-focused, secure, and sophisticated healthcare solutions.`
    },
    {
        img: EDV,
        heading: 'Detection of Area Equalities Square Dissection',
        paragraph: 'Utility Solution/ Web Application',
        category: 'utility-solution',
        more:'The project is a cutting-edge desktop application designed to detect area equalities in plane surfaces with remarkable precision, achieving an accuracy of 1e-12mm. It exemplifies the ability to solve intricate computational geometry challenges through advanced algorithm development.',
        more1:'The solution was crafted to address the complexities of square dissection and ensure high-level accuracy in calculations. The application provides a seamless experience, delivering a powerful tool for analyzing and verifying geometric area equalities. The successful completion of this project highlights expertise in computational problem-solving and innovative software design.'
    },
    {
        img: ERG,
        heading: 'Networking Platform for Employee Resource Groups (ERG)',
        paragraph: 'Utility Solution/ Web Platform',
        category: 'utility-solution',
        more:`This state-of-the-art networking platform is specifically designed to enhance communication and collaboration within Employee Resource Groups (ERGs) for leading global organizations. The platform solves key challenges such as ensuring smooth communication across diverse employee groups and supporting strategic partnerships. By offering a dedicated internal network and a separate partnership network, it facilitates both internal collaboration and external alliances. The structured group and channel system further enhances communication within ERGs, promoting a sense of community, fostering employee engagement, and supporting diversity initiatives across the organization.`
    },
    {
        img: Godrej,
        heading: 'Spare Parts Management Portal',
        paragraph: 'Utility Solution/ Web Platform',
        category: 'manufacturing',
        more:`The Spare Parts Management Portal offers an intuitive, web-based platform that seamlessly integrates 2D and 3D manuals with an advanced parts management system. Designed to streamline the user experience, it allows customers, dealers, and employees to access detailed manuals and efficiently manage spare parts. The portal's robust features enhance operational efficiency, reduce downtime, and simplify ordering, all while being scalable and secure. This solution has significantly improved productivity and resource management, ensuring a smoother and more effective spare parts process.`
    },
    {
        img: IdeaManagement,
        heading: 'Idea Management Portal',
        paragraph: 'Utility Solution/ Web Platform',
        category: 'utility-solution',
        more:`The Idea Management Portal revolutionizes the way ideas are submitted, approved, and transformed into projects. This platform supports a highly configurable workflow and is accessible in multiple languages, catering to users across 122 countries. It simplifies the entire idea lifecycle, from submission to task creation, fostering innovation and enhancing productivity. By accommodating a diverse global workforce, the portal ensures seamless idea management and widespread adoption, driving successful project outcomes and operational efficiency.`
    },
    {
        img: PressureVesselsManagement,
        heading: 'Pressure Vessel Lifecycle Management',
        paragraph: 'Utility Solution/ Web Platform',
        category: 'manufacturing',
        more:`The Pressure Vessel Lifecycle Management solution provides a comprehensive, mobile and web-based system for overseeing the entire inspection process of pressure vessels. Focusing on quality control, it manages welding quality, test results, and periodic inspections with dynamic forms and advanced validation. This solution supports detailed observations, multimedia evidence, and regulatory compliance, streamlining inspections and enhancing quality control. The result is improved documentation, more accurate inspections, and a robust system that supports effective pressure vessel management throughout its lifecycle.`
    },
    // // {
    // //     img: iElection,
    // //     heading: 'iElection',
    // //     paragraph: 'Mobile Apps/ Service',
    // //     category: 'utility-solution',
    // //     more:`The iElection platform is a breakthrough in managing elections in India, tackling the unique complexities of the electoral process. Designed with advanced data extraction techniques and linguistic algorithms, it groups individuals into family units, bridging the gap across multiple regional languages. This innovation greatly enhances a candidate's ability to manage voter data and streamline outreach. By catering to the vast and diverse population of India, the platform ensures efficient and effective election management.`,
    // //     more1:` iElection was recognized as the "Highly Innovative Application of 2012" by the Government of Maharashtra, further cementing its impact on improving transparency and operational efficiency within the electoral system. Its ability to simplify the management of voter information while maintaining accuracy makes it a vital tool for large-scale election campaigns. Today, iElection continues to be a powerful resource for election organizers, contributing to the integrity and efficiency of the Indian democratic process.`
    // },

    
    {
        img: shoeSelector,
        heading: 'Foot Measurement Mobile Application',
        paragraph: 'Mobile Apps/ Service',
        category: 'utility-solution',
        more:`The application enables users to capture a photograph of their foot, which is then processed to accurately extract the foot's dimensions. After obtaining these measurements, the app provides personalized recommendations for shoes that offer the best fit based on the user’s specific foot size and shape. Additionally, the app features a visual representation that shows how well the user's foot aligns with the selected shoe, offering a clear graphical display of the fit. This helps users make more informed decisions when choosing footwear, ensuring comfort and suitability.`,
        // more1:`The app taps into a vast database of footwear options, ensuring users are presented with shoes that fit their exact profile. By personalizing the shopping experience, the app streamlines the process, helping users find the perfect pair with minimal effort. This has led to higher customer satisfaction and simplified how people shop for shoes online. The Shoe Selector App showcases the potential of combining cutting-edge technology with a customer-centric approach, making it a leader in personalized retail experiences.`
    },
    {
        img: EightRate,
        heading: 'The Restaurant Event Management Application',
        paragraph: ' Mobile Apps/ Service',
        category: 'utility-solution',
        more:`The Restaurant Event Management application is designed to simplify restaurant event planning and execution for chefs, bartenders, waiters, and sommeliers. It provides professionals with an intuitive interface to organize exclusive events, manage guest lists, and handle payments efficiently. The platform fosters collaboration among industry professionals, creating a trusted network to ensure the success of each event. Key features include seamless guest invitations and payment management, offering a user-friendly guide for event optimization.`, 
        more1:`By fostering a network of trusted industry professionals and providing a seamless user experience, the application ensures that every event is well-organized, engaging, and efficiently handled. Its comprehensive features and user manual offer a complete guide for optimizing event management, leading to successful and memorable experiences.`
    },
    {
        img: Allevi8,
        heading: 'Meditation App',
        paragraph: 'Mobile Apps/ Service',
        category: 'healthcare',
        more:` The meditation app is a thoughtfully crafted platform developed by Intellection to guide users through their meditation journey. Developed under the mentorship of renowned teacher Ian Gawler, the app is available on both Android and iOS devices and offers a range of guided meditation sessions. Its simple, intuitive interface provides a seamless experience for both beginners and experienced meditators. A key feature is the progress tracking system, which helps users monitor their growth and commitment to their practice.`,
        more1:`The app also includes a minimized visual mode, allowing users to meditate while multitasking on their devices. To support the app's ongoing development, a donation feature is incorporated, enabling users to contribute. Known for its high-quality audio, insightful guidance, and practical tools, the app makes meditation accessible and enjoyable. With its blend of advanced features and thoughtful design, it offers an enriching and supportive experience, helping users deepen their meditation practice.`
    },
    {
        img: AirtelTV,
        heading: 'Airtel Digital TV',
        paragraph: 'Media & TV/ Mobile Application',
        category: 'media-entertainment',
        more:`Collaborating with Airtel Digital TV, we developed a mobile application designed to enhance user interaction with TV programming. The app enables users to browse live and upcoming shows, set reminders, and even schedule recordings directly on their set-top boxes. Despite the tight timeline and the complexity of integrating with Airtel's existing infrastructure, the project was completed using agile methodologies, ensuring flexibility and timely delivery.`,
        more1:` The result is a robust, user-friendly mobile app that allows Airtel customers to manage their TV schedules remotely, adding a layer of convenience to their viewing experience. This project not only met Airtel’s expectations but also highlighted our expertise in delivering high-quality, efficient solutions under pressure. The app has become a valuable tool for users, significantly improving their ability to control and personalize their TV viewing.`
    },
    {
        img: TelevisionAudienceMeasurement,
        heading: 'Outlier Detection',
        paragraph: 'Media & TV/ Service',
        category: 'media-entertainment',
        more:`For over 10 years, Intellection has provided cutting-edge technological solutions to India’s premier television audience measurement body. Our suite of projects has transformed key areas such as asset management, fraud detection, data handling, and client interactions. We developed systems to efficiently track TV meters and displays, ensuring precise video asset management using unique IDs. To address fraud, we developed an algorithm that identifies patterns based on viewing habits and provides precise data to the vigilance team.
        Our client management tool has streamlined communication with broadcasters and advertisers, optimizing business interactions. These solutions have significantly improved the accuracy, efficiency, and security of television audience measurement processes, reinforcing Intellection's commitment to excellence.`,
        // more1:`The client faced numerous challenges, starting with the management of a vast inventory of television meters, displays, and remote controls, which needed to be accurately tracked. Additionally, a system to create unique IDs for video assets was required to ensure that every broadcast could be effectively monitored. Fraud detection was another significant challenge, as fraudulent activities could compromise data integrity. The client also needed an efficient system for generating town codes based on census data to improve the accuracy of audience insights across diverse regions. Moreover, there was a need for a mobile application that could provide live TV viewing data across the country in real time, along with a client management application to facilitate seamless interaction with broadcasters and advertisers.`,
        // objective1:`In response to these challenges, Intellection developed several tailored solutions. The asset management system it introduced allowed the client to track television meters, displays, and remote controls with greater efficiency. The creation of a unique ID system for video assets enabled accurate identification and tracking, ensuring that the right data was captured at the right time. To address fraud detection, Intellection implemented advanced algorithms and a comprehensive ticketing system that significantly reduced fraudulent activities and protected the integrity of audience measurement data. The generation of town codes based on census data was another significant achievement, which allowed for more precise geographic tracking of television audiences. Additionally, Intellection developed a mobile application that provided real-time access to TV viewing data across the country, and a client management application to streamline interactions with broadcasters and advertising agencies.`,
        // objective2:`The outcome of these efforts was transformative for the client. The asset management system improved the efficiency of managing equipment, reducing operational overheads. The unique ID system for video assets led to more precise tracking and measurement of audiences. Fraud detection algorithms drastically cut down on fraudulent activities, ensuring that the data remained reliable and trustworthy. Town codes generated based on census data allowed for more localized audience insights, which improved the targeting of television content and advertising. The mobile app and client management system facilitated better communication between the client and its stakeholders, leading to more efficient workflows and improved collaboration with broadcasters and advertisers.`
    },
    {
        img: BARCHousehold,
        heading: 'Household Recruitment Application',
        paragraph: 'Media & TV/ Service',
        category: 'media-entertainment',
        more:`Intellection developed a powerful application for BARC India to optimize the collection of household demographic data. This web and mobile-based platform uses dynamic forms with validation, enabling data collection even in offline environments. The system ensures seamless data synchronization once the connection is restored. By providing a comprehensive and efficient platform for household data gathering, Intellection has greatly enhanced BARC India's capacity to collect and manage household information. The application’s offline capabilities, along with its user-friendly design, ensure accurate and reliable data collection under various conditions.`,
        // more1:`The client faced significant challenges in creating an effective data collection tool. One of the main issues was managing a large number of data fields in a way that was both efficient and accurate. The system needed to feature dynamic forms with validation to ensure data accuracy, and it had to be robust enough to handle large-scale data collection efforts. Another challenge was ensuring the application worked seamlessly in areas with limited internet connectivity, necessitating an offline data storage mechanism that allowed users to collect data without an active network. Ensuring that this offline data was synchronized with the main server when the network was available was another critical requirement.`,
        // objective1:`Intellection's solution addressed these challenges through the development of a dynamic and scalable data collection application. The application was designed with advanced form validation techniques to ensure the accuracy and completeness of the data being gathered. The system’s dynamic forms allowed the collection of a wide range of demographic information while maintaining a user-friendly experience. To deal with connectivity issues, Intellection equipped the mobile application with offline storage capabilities, ensuring that data could be collected even in remote areas. The solution also included auto and manual synchronization options to seamlessly integrate offline data once network connectivity was restored.`,
        // objective2:`The results of this project were highly beneficial for BARC India. The household recruitment application significantly improved the quality and accuracy of the data being collected, allowing the client to gather detailed household demographics more efficiently. The use of dynamic forms and advanced validation ensured high-quality data input, while the offline data storage and sync features allowed for flexibility in data collection, even in areas with poor connectivity. This project demonstrated Intellection's ability to create scalable, reliable, and effective solutions to address complex data collection needs.`
    },
    {
        img: BAARCCluster,
        heading: 'Cluster Automation Application',
        paragraph: 'Media & TV/ Service',
        category: 'media-entertainment',
        more:`Our Cluster Automation Application revolutionizes the household recruitment process for BARC India. By incorporating customizable control variables and priority settings, this web-based application ensures precise cluster formation, reflecting the accurate demographic proportions of the Universe Design. The advanced algorithms, combined with data visualization tools, enable BARC India to streamline their recruitment process and make more informed decisions. This solution has not only improved the efficiency of cluster formation but also significantly enhanced the quality and accuracy of the data collected.`,
        // more1:`One of the main challenges the client faced was managing multiple control variables during cluster formation, including Household Establishment (HE), Household Size (HH), Market of Select Retail (MOSR), and Language. These control variables had to be carefully balanced, with priorities and tolerance levels set for accurate cluster formation. Additionally, maintaining the proportionality of the universe while creating clusters was a complex process that required a high level of precision and strategic planning to ensure data representativeness.`,
        // objective1:`To tackle these challenges, Intellection developed a web-based application with several key features. The application allowed for the customization of control variables, enabling flexibility in the household selection process. Priority and tolerance settings were implemented to ensure that clusters adhered to the Universe Design, providing more representative data. Advanced algorithms were incorporated into the application to automate the cluster formation process, ensuring that the resulting clusters accurately mirrored the demographics and characteristics of the target population. The user interface was designed to be intuitive, allowing BARC India personnel to easily manipulate control variables and create clusters with minimal effort.`,
        // objective2:`The outcome of the project was a streamlined and efficient process for household selection and cluster formation. By accurately reflecting the Universe Design, the application improved the representativeness of the data being collected. This resulted in more accurate audience measurement metrics and enhanced decision-making capabilities for BARC India. The application’s success underscores Intellection’s ability to develop advanced, customizable solutions that address complex client needs and improve operational efficiency.`
    },
    {
        img: CensusData,
        heading: 'Census Data Processing for Towncode Generation',
        paragraph: 'Media & TV/ Service',
        category: 'media-entertainment',
        more:`Intellection developed a web-based platform to tackle the challenges associated with processing large volumes of census data for BARC India. Our platform efficiently handles the complexity of resolving location name conflicts and generates unique town codes, crucial for accurate data categorization and management. By employing sophisticated algorithms, we ensured the successful generation of town codes, greatly improving the management and analysis of household data. This project demonstrated Intellection’s expertise in handling large-scale data challenges and delivering precise solutions.`,
        // more1:`The primary challenge BARC India faced in this project was the sheer volume and complexity of census data that needed to be processed. The client had to account for multiple towns and villages across various regions of India, many of which shared the same name, leading to potential confusion and misidentification. Therefore, a method was required to assign unique town codes to each location accurately, despite the similarities in names and geographic proximity.`,
        // objective1:`Intellection developed a scalable web-based platform to process the extensive census datasets. Advanced algorithms were employed to analyze the demographic data and assign unique town codes to each location. Special attention was given to resolving conflicts where multiple places had the same name, ensuring that each location was correctly identified and differentiated. The platform was designed with an intuitive interface, allowing users to easily navigate the data and generate the necessary town codes with minimal effort.`,
        // objective2:`The successful implementation of this solution provided BARC India with a standardized framework for managing and analyzing data from various geographic locations. The unique town codes enhanced the accuracy of the demographic analysis and ensured that data from panel households were correctly associated with their respective locales. This project highlighted Intellection’s expertise in handling large datasets and developing efficient solutions for complex data management tasks.`
    },
    
];

const OurWork = () => {
  

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const category = params.get('category');
    console.log(category);

    const filteredData = Data?.filter(item => {
        if (category) {
            return item.category.toLowerCase() === category.toLowerCase();
        }
        return true;
    });
   

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [filteredData]);

    return (
        <>
         <Helmet>
                <title>Intellection: Portfolio/Our Work</title>
                <meta name="description" content="Intellection offers a wide range of innovative software solutions including web development, mobile app development, AI/ML services, chatbot development, and custom software tailored to business needs." />
                <meta name="keywords" content="Web Development, Mobile App Development, Chatbot Development, Data Science, Data Visualization, AI/ML, Media & Entertainment, Healthcare" />
                <meta name="robots" content="index, follow" />
                {/* Open Graph tags for social sharing */}
                <meta property="og:title" content="Innovative Solutions for Your Business" />
                <meta property="og:description" content="Explore our innovative services and solutions for industries across the globe. We deliver expertise in various sectors." />
                {/* <meta property="og:image" content="https://www.yourwebsite.com/path-to-your-image.jpg" /> */}
                <meta property="og:url" content="https://www.intellection.in/company-overview" />
                <meta property="og:type" content="website" />
                {/* <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home - Innovative Solutions for Your Business" />
                <meta name="twitter:description" content="Explore our innovative services and solutions for industries across the globe." />
                <meta name="twitter:image" content="https://www.yourwebsite.com/path-to-your-image.jpg" /> */}
            </Helmet>
            <NavBar />
            <OurProject data={filteredData} category={category} />
            <ContactWithUs />
        </>
    );
}

export default OurWork;
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';

const numbers = [
    { count: 15, title: 'Years Experience' },
    { count: 100, title: 'Projects' },
    { count: 75, title: 'Clients' },
    { count: 7, title: 'Technologies' },
]

const Experience = () => {
    // Using Intersection Observer
    const [ref, inView] = useInView({
        triggerOnce: true, // Trigger the animation only once
        threshold: 0.3, // Trigger when 30% of the component is visible
    });

    return (
        <div style={{ padding: '0px 60px', marginTop: 40,marginBottom:40 }} ref={ref}>
            <Row>
                {numbers.map((item, index) => (
                    <Col key={index} xs={12} md={6} lg={3} className="experience-col">
                        <div style={{ textAlign: 'center' }}>
                            <h2 style={{ color: 'rgba(111, 45, 168, 1)', fontSize: 64, fontWeight: 'bold', fontFamily: FONT.primaryFont }}>
                                {inView && (
                                    <CountUp start={0} end={item.count} duration={2.5} suffix="+" />
                                )}
                            </h2>
                            <p style={{ color: 'rgba(62, 62, 62, 1)', fontSize: 20, fontWeight: 'bold', fontFamily: FONT.primaryFont, marginTop: '-14px', marginBottom: 7 }}>
                                {item.title}
                            </p>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default Experience;

import React from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';
import Navv from '../Components/Navv';
import './WhyChooseUs.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import solution from '../assets/images/solution.png';
import smartcontrol from '../assets/images/smart-control.png';
import problemSolving from '../assets/images/problem-solving.png';
import technology from '../assets/images/customer-support.png';
import intellectionvector from '../assets/images/intellection_vector.png'

const WhyChooseUs = () => {

    const navigate = useNavigate();
    const cardData = [
        {
            id:1,
            category:'buildingmvp',
            img: solution,
            title: "Building MVP",
            text: "We specialize in MVP development, guiding clients from concept to launch. Our efficient process focuses on essential features, delivering market-ready products that validate ideas and accelerate growth with precision."
        },
        {
            id:2,
            img: technology,
            category:'software-solution-partner',
            title: "Software Solutions Partner",
            text: "We provide tailored software solutions, including custom development, enterprise applications, and cloud-based platforms, designed to solve complex business challenges. Our expert team delivers scalable, user-friendly systems that drive growth and efficiency, ensuring reliability, security, and seamless integration with your evolving business needs."},
        {
            id:3,
            category:'technology-partner',
            img: problemSolving ,
            title: "Technology Partner",
            text: "We deliver AI and ML-driven technology solutions, helping businesses innovate through predictive analytics, automated pipelines, and next-gen tech integration. Our team is dedicated to enhancing operational efficiency and enabling data-driven decision-making for future-proof digital transformation."
        },
        {
            id:4,
            category:'end-to-end-sotfware-product',
            img: smartcontrol,
            title: "End to End Software Product",
            text: "We offer complete end-to-end software product development, overseeing every phase from design to deployment. Our approach ensures seamless integration, high quality, and efficiency, delivering tailored solutions that meet your business needs."
        }
    ];

    const handleCardClick = (category) => {
        navigate(`/what-we-do/${category}`);
    };
    return (
        <>
        

            <div className='WhatWeDoComponent' style={{ marginTop: '0px', backgroundColor: 'rgba(240, 242, 243, 0.6)', paddingTop: '30px', paddingBottom: '30px' }}>
                <Row>
                    <Col md={12}>
                        <p style={{ fontSize: 42, fontWeight: 600, fontFamily: FONT.primaryFont,textAlign:'center' }}>What We <span style={{ color: 'rgba(111, 45, 168, 1)' }}>Do?</span></p>
                    </Col>
                    {/* <Col md={2}>
                        <img src={intellectionvector} height={150} />
                    </Col> */}
                </Row>
                <Row>
                    <Col md={12}>
                        <p style={{ marginTop: '10px',textAlign:'center' }}>We understand our clients' needs to deliver solutions that offer long-term value. Our strong client base covers the USA, France, Australia, Japan, Singapore and India. Our expertise spans multiple industries, including Telecom, Education, Healthcare, Automotive, Utility Solutions and Media & Research.</p>
                    </Col>
                </Row>


                <Row className="mt-5"  >
                    {cardData.map((card, index) => (
                        <Col key={index} xs={12} md={6} lg={3} className="mb-4 d-flex" style={{marginTop:"-20px"}}>
                            <Card className='whychooseuscard d-flex flex-column' style={{cursor:'pointer'}} onClick={() => handleCardClick(card.category)}>
                                <Card.Img variant="top" src={card.img} />
                                <Card.Body className="d-flex flex-column align-items-center flex-grow-1">
                                    <Card.Title className="cardtitle">{card.title}</Card.Title>
                                    <Card.Text className="cardtext">{card.text}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                    ))}
                </Row>
            </div>
        </>
    )
}
export default WhyChooseUs
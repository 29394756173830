import React from 'react'
import ContactWithUs from '../Components/ContactWithUs'
import NavBar from '../Components/NavBar'
import OurProduct from "../Components/OurProduct"
import { Helmet } from 'react-helmet';

const OurProducts = () => {
    React.useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
        <Helmet>
                <title>Intellection: Our Products</title>
                <meta name="description" content="Intellection offers a wide range of innovative software solutions including web development, mobile app development, AI/ML services, chatbot development, and custom software tailored to business needs." />
                <meta name="keywords" content="Web Development, Mobile App Development, Chatbot Development, Data Science, Data Visualization, AI/ML, Media & Entertainment, Healthcare" />
                <meta name="robots" content="index, follow" />
                {/* Open Graph tags for social sharing */}
                <meta property="og:title" content="Innovative Solutions for Your Business" />
                <meta property="og:description" content="Explore our innovative services and solutions for industries across the globe. We deliver expertise in various sectors." />
                {/* <meta property="og:image" content="https://www.yourwebsite.com/path-to-your-image.jpg" /> */}
                <meta property="og:url" content="https://www.intellection.in/company-overview" />
                <meta property="og:type" content="website" />
                {/* <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home - Innovative Solutions for Your Business" />
                <meta name="twitter:description" content="Explore our innovative services and solutions for industries across the globe." />
                <meta name="twitter:image" content="https://www.yourwebsite.com/path-to-your-image.jpg" /> */}
            </Helmet>
            <NavBar />
            <OurProduct />
            <ContactWithUs />
        </>
    )
}
export default OurProducts
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { COLORS } from '../utils/Colors'
import NavBar from '../Components/NavBar'

import Leader from '../assets/images/Leader.png'
import Background from '../assets/images/LeaderBg.png'
import { FONT } from '../utils/Font';

const OurLeadership = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar />
            <div className="AboutUsHome" style={{ marginTop: 100, marginBottom: '100px' }}>
                <p style={{ color: COLORS.heading, fontSize: 30, fontWeight: 'bold', fontFamily: FONT.primaryFont }}>
                    Our Leadership
                </p>

                <Row>
                    <Col md={7}>
                        <ul style={{ textAlign: 'justify', marginBottom: 40, fontFamily: FONT.primaryFont }}>
                            <li style={{ paddingBottom: 5 }}>At Intellection, our vision and direction are shaped by our esteemed CEO and Founder. With a blend of innovation, strategic foresight, and unwavering dedication, Dr. Shailesh has been the cornerstone of our company's growth and success.</li>
                            <li style={{ paddingBottom: 5 }}>Meet Our CEO and Founder, Dr. Shailesh Deshpande, Chief Executive Officer (CEO) and Founder, an IIT Bombay Alumni.</li>
                            <li style={{ paddingBottom: 5 }}>Dr. Shailesh founded Intellection in 2005, driven by a passion for technology and a desire to solve real-world problems through innovative software solutions. With over 20+ years of experience in the industry, he has transformed the company from a nascent startup into a leading player in the software market.</li>
                            <li>Dr. Shailesh's vision for Intellection has always been clear: to create technology solutions that not only meet the needs of our clients but also exceed their expectations. Under his leadership, we have continuously pushed the boundaries of what is possible, embracing cutting-edge technologies and developing groundbreaking products that drive business success.</li>
                        </ul>
                    </Col>
                    <Col md={4} xs={12}>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '-90px',
                                backgroundImage: `url(${Background})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: '100%',
                                height: 400,
                                backgroundRepeat: 'no-repeat',
                                padding: 60,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img src={Leader} alt="Leadership" style={{ marginTop: '-40px' }} width={240} height={240} />
                        </div>
                    </Col>
                </Row>

                <ul style={{ textAlign: 'justify', fontFamily: FONT.primaryFont, marginTop: '-30px' }}>
                    <li style={{ paddingBottom: 5 }}>As CEO, Dr. Shailesh is responsible for setting the strategic direction of the company. His deep understanding of market trends, combined with an ability to anticipate future needs, allows us to stay ahead of the competition. He is adept at identifying new opportunities and navigating the complexities of the ever-evolving tech landscape, ensuring that Intellection remains at the forefront of innovation.</li>
                    <li style={{ paddingBottom: 5 }}>Dr. Shailesh's commitment to excellence permeates every aspect of our business. He believes in fostering a culture of continuous improvement, where quality and performance are paramount. This dedication to excellence is reflected in the high standards we set for our products and services, as well as in the exceptional results we deliver to our clients.</li>
                </ul>

                <div style={{ marginTop: '50px', textAlign: 'center' }}>
                    <p style={{ fontFamily: FONT.primaryFont, fontSize: '24px', fontWeight: 'bold', color: COLORS.heading }}>
                        A glimpse of an interview of the CEO over a local Radio Channel.
                    </p>

                    <div className="video-container">
                        <iframe
                            src="https://www.youtube.com/embed/Wps_zn180u4?si=zVPjzURJzFji22vK"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        />
                    </div>
                </div>
            </div>

            {/* Inline styles for responsiveness */}
            <style>
                {`
                    .video-container {
                        position: relative;
                        padding-bottom: 55%;
                        height: 0;
                        overflow: hidden;
                        max-width: 100%;
                        background: #000;
                    }

                    .video-container iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    /* Media query for larger screens to set 80% width and height */
                    @media (min-width: 992px) {
                        .video-container {
                            width: 80%;
                            padding-bottom: 40%;
                            height: 200px;
                            margin: 0 auto; /* Center the video container */
                        }
                    }
                `}
            </style>
        </>
    );
};

export default OurLeadership;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Navv from './Navv';
import ai from '../assets/images/AInew.png';
import imageProcessing from '../assets/images/ImageProcessing.png';
import cad from '../assets/images/cad_cam.png';
import chatbot from '../assets/images/chatbot.png';
import datascience from '../assets/images/datascience.png';
import androidApp from '../assets/images/androidApp.png';
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';
import CarouselCompomnent from './CarouselComponent';
import Meditation from '../assets/images/meditation.png';
import computer from '../assets/images/computer.png';
import AdirA from '../assets/images/AdirA.png';
import { useParams,useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import { FaArrowLeftLong } from 'react-icons/fa6';

const Data = [
    {
        img: Meditation,
        heading: 'Meditation Gateway',
        paragraph: 'Meditation Gateway is a powerful tool for establishing and sustaining a regular meditation practice.'
    },
    {
        img: computer,
        heading: 'BARC India: Audit Management Application',
        paragraph: 'Media / Web Platform'
    },
    {
        img: AdirA,
        heading: 'AdirA',
        paragraph: 'AdirA’s contraceptive selection webapp is a conversational chatbot that helps users pick the right contraception based on their needs.'
    },
];

const ServiceData = [
    {
        id: 1,
        img: imageProcessing,
        category: 'image-processing',
        heading: 'Image Processing',
        para1: 'Transforming pixels into powerful insights with our software solutions.',
        para2: 'Services',
        para3: `Intellection specializes in harnessing the power of pixels to unlock invaluable insights through state-of-the-art software solutions.
        With a rich history of expertise in image and video processing, we excel in extracting meaningful data from complex visual datasets. Our dedicated team is proficient in navigating the intricate algorithms required for tasks such as object detection, recognition, image segmentation, and facial recognition.
        Across various industries from apparel to interior decoration, manufacturing to broadcasting we have delivered tailored solutions that elevate the value of our client's offerings. Our commitment to innovation and precision ensures that our software solutions drive tangible results and empower enterprises to stay ahead in today's competitive landscape.
        `
    },
    {
        id: 5,
        img: ai,
        heading: 'AI/ML',
        category: 'ai-ml',
        para1: 'Revolutionizing the future with AI/ML powered software solutions.',
        para2: `Unlock the potential of AI/ML with our cutting-edge solutions.`,
        para3: `Intellection Software Solutions is at the forefront in AI/ML-based applications, providing businesses with cutting-edge solutions to automate processes, enhance decision-making, and gain a competitive edge.`,
        para4: `Our expert team of data scientists and machine learning engineers develops robust algorithms to solve complex business challenges with data-driven precision.
        We specialize in Machine Learning, Data Analytics, Predictive Analysis, Recommendation Systems, and Computer Vision, tailoring scalable solutions to your unique needs.`,
        para5: ` Our successful implementations span fraud detection, healthcare, insurance, automated chatbots, business process automation, and advanced computer vision.`,
        // para6: ` From successful implementations in fraud detection, healthcare, and insurance to automated chatbots, business process automation, and advanced computer vision applications, our track record speaks volumes.`,
    },
    {
        id: 3,
        img: chatbot,
        heading: 'Chatbot Development',
        category: 'chatbot-development',
        para1: 'Crafting chatbots for effortless customer engagement using Adira Platform',
        para2: `Crafting HIPAA-compliant chatbots for healthcare organizations using Conversational AI Development platforms.`,
        para3: `In the realm of business outreach and customer service enhancement, chatbots have become indispensable. With extensive expertise in crafting HIPAA-compliant chatbots for healthcare organizations using Conversational AI Development platforms like Orbita, we're committed to delivering highly efficient, user-friendly solutions tailored to our client's precise needs. `,
        para4: `Intellection Software excels in creating intelligent conversational chatbots designed to enhance customer engagement, streamline business operations, and provide specialized support in industries like healthcare and patient services. Our chatbots are tailored to perform specific functions, whether it’s managing customer inquiries, processing transactions, or assisting patients in healthcare settings.`,
        // para5:`Equipped with proficiency in diverse programming languages such as Python, Node.js, and Ruby, our development team leverages cutting-edge tools and technologies to ensure top-notch quality and scalability, capable of handling substantial traffic. Rigorous testing at every phase guarantees a bug-free, seamlessly performing chatbot that meets and exceeds expectations.`


    },
    {
        id: 4,
        img: datascience,
        category: 'data-science',
        heading: 'Data Science & Visualization',
        para1: 'Simplifying big data through impactful visual representation.',
        para2: 'Empowering Decisions Through Data-Driven Insights by Our Experts',
        para3: `Intellection Software offers comprehensive data science and visualization services that transform complex datasets into actionable insights, empowering data-driven decision-making and strategic planning. `,
        para4: `Leveraging advanced mathematical and statistical methodologies with expert programming, we dive deep into data to uncover valuable insights.
        Our services span the entire data lifecycle—from collection and processing to analysis and interpretation. `,
        para5: `We create compelling visualizations, including charts, graphs, and interactive dashboards, to highlight patterns and trends, enabling well-informed decisions. Specializing in Data Science techniques, we offer bespoke solutions tailored to propel you toward your objectives.`,
        para7: `Our Process:`,
        para8: `We begin by evaluating your existing data sources and identifying the key metrics that are most relevant to your business objectives. Collaborating with your team, we define data collection methods and create a tailored data strategy that aligns with your organizational goals.`,
        para9: `Using advanced statistical methods and machine learning algorithms, we analyze your data to uncover patterns, trends, and actionable insights. This process involves data cleaning, normalization, and feature engineering to ensure accuracy and relevance in the analysis.`,
        para10: `We transform complex data into visually compelling dashboards and reports that are easy to interpret and act upon. Our visualizations are designed to highlight key insights, supporting informed decision-making across all levels of your organization.`
    },
    {
        id: 2,
        img: androidApp,
        category: 'end-to-end-application-development',
        heading: 'End-To-End Enterprise Level Application Development',
        para1: 'Transforming ideas into scalable enterprise applications.',
        para2: 'Leveraging expertise in math, stats, and programming, we extract key insights from data.',
        para3: `At Intellection Software, we deliver enterprise-level application development services designed to meet the complex needs of large organizations. Our solutions are robust, scalable, and secure, ensuring they drive business efficiency and support mission-critical operations across various sectors. `,
        para4: ` We align our development process with your specific goals, creating future-proof applications that integrate seamlessly with your existing infrastructure. With a strong emphasis on security and quality, we provide solutions that enhance operations, improve collaboration, and support your organization’s long-term success.`,
        // para5: `Specializing in a broad spectrum of Data Science techniques such as data mining, machine learning, and predictive modeling, we are steadfast in delivering personalized, tailored solutions precisely aligned with your unique needs, ensuring your objectives are not just met, but exceeded.`
    },
    {
        id: 6,
        img: cad,
        category: 'mobile-application',
        // heading: 'Mobile Application Development',
        para1: "Empowering growth with cutting-edge mobile applications.",
        // para2: "Our expertise in mobile application development allows us to create custom, user-friendly, and highly efficient apps tailored to your specific business requirements. Whether you need an app for iOS, Android, or cross-platform use, we leverage the latest technologies to deliver solutions optimized for speed and performance.",
        para3: `Our expertise in mobile application development allows us to create custom, user-friendly, and highly efficient apps tailored to your specific business requirements. Whether you need an app for iOS, Android, or cross-platform use, we leverage the latest technologies to deliver solutions optimized for speed and performance.`,
        para4: `From simple design tools to intricate CAD software systems, our portfolio boasts a wide spectrum of projects. This rich experience equips us to deliver top-notch solutions precisely tailored to meet the distinctive needs of our clients.`
    },

];

const AI_MLServices = () => {
    const navigate = useNavigate();
    const { category } = useParams();
    const servicee = ServiceData.find(item => item.category === category);
    console.log(servicee);
    React.useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);

    const handleBackClick = () => {
        // navigate(-1); 
        navigate('/services')
    };

    return (
        <>
            <NavBar />
            <div className='WhatWeDoComponent' style={{ marginTop: '90px', marginBottom: 120 }}>
            <button style={{ border: 'none', backgroundColor: 'white' }} onClick={handleBackClick}>
                    <FaArrowLeftLong style={{ marginRight: '8px', color: '#6F2DA8', fontSize: '30px' }} />
                </button>
                <Row>
                    {servicee && (
                        <>
                            <Col lg={7} md={6}>
                                <p style={{ fontSize: 42, fontWeight: 'bold', fontFamily: FONT.primaryFont, marginTop: '5px' }}>{servicee.heading}</p>
                                {/* <p style={{ color: 'rgba(101, 101, 101, 1)', fontSize: 18, fontFamily: FONT.primaryFont, marginTop: '-20px' }}>{servicee.para1}</p> */}
                                <div>
                                    {servicee.category === "mobile-application" ? (
                                        <>
                                            <h3 style={{ fontFamily: FONT.fontBold }}>Mobile Application Development</h3>
                                            <p style={{ color: 'rgba(101, 101, 101, 1)', fontSize: 18, fontFamily: FONT.primaryFont, marginTop: '5px' }}>{servicee.para1}</p>
                                            <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify' }}>Our expertise in mobile application development allows us to create custom, user-friendly, and highly efficient apps tailored to your specific business requirements. Whether you need an app for iOS, Android, or cross-platform use, we leverage the latest technologies to deliver solutions optimized for speed and performance.</p>
                                            <h4 style={{ fontFamily: FONT.fontBold, textAlign: 'justify' }}>Our Process:</h4>
                                            <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify' }}><span style={{ fontFamily: FONT.fontBold }}>1. Collaborative Planning:</span> We start by understanding your needs, target audience, and the purpose of your app. This involves close collaboration with your team to create detailed wireframes and mockups that visualize the design and functionality.</p>
                                            <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify' }}><span style={{ fontFamily: FONT.fontBold }}>2. Expert Development:</span> With expertise in programming languages like Swift, Objective-C, Java, Kotlin, and React Native, our development team builds robust apps that handle complex features such as GPS computation, collaboration tools, and payment systems.</p>
                                            <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify' }}><span style={{ fontFamily: FONT.fontBold }}>3. Rigorous Testing:</span> Our commitment to quality means that every app undergoes rigorous testing to ensure it’s bug-free and performs as expected. We also provide ongoing updates to keep your app optimized for the latest devices and operating systems.</p>

                                        </>
                                    ) : (
                                            <>
                                                <p style={{ color: 'rgba(101, 101, 101, 1)', fontSize: 18, fontFamily: FONT.primaryFont, marginTop: '-20px' }}>{servicee.para1}</p>
                                                <p style={{ fontSize: 18, color: 'rgba(111, 45, 168, 1)', fontWeight: 600, textAlign: 'justify' }}>{servicee.para2}</p>
                                                <p style={{ fontSize: 16, textAlign: 'justify' }}>{servicee.para3}</p>
                                                <p style={{ fontSize: 16, textAlign: 'justify', marginTop: '-15px' }}>{servicee.para4}</p>
                                                <p style={{ fontSize: 16, textAlign: 'justify', marginTop: '-15px' }}>{servicee.para5}</p>
                                                <p style={{ fontSize: 16, textAlign: 'justify', marginTop: '-15px' }}>{servicee.para6}</p>
                                            </>
                                        )}
                                </div>
                            </Col>
                            <Col lg={5} md={6} style={{marginTop:60}}>
                                <img src={servicee.img} style={{ marginRight: 10, height: 360, width: 460 }} className="img-fluid" alt='About us' />
                            </Col>
                        </>
                    )}
                </Row>
                {servicee.category === "data-science" ? (
                    <>
                        <p style={{ fontSize: 18, textAlign: 'justify', fontWeight: 600, marginTop: 8 }}>Our Process</p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>Data Assessment and Strategy: </span>We begin by evaluating your existing data sources and identifying the key metrics that are most relevant to your business objectives. Collaborating with your team, we define data collection methods and create a tailored data strategy that aligns with your organizational goals. </p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>Data Analysis and Modeling:</span>Using advanced statistical methods and machine learning algorithms, we analyze your data to uncover patterns, trends, and actionable insights. This process involves data cleaning, normalization, and feature engineering to ensure accuracy and relevance in the analysis.</p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>Visualization and Reporting: </span>We transform complex data into visually compelling dashboards and reports that are easy to interpret and act upon. Our visualizations are designed to highlight key insights, supporting informed decision-making across all levels of your organization.</p>
                    </>
                ) : servicee.category === "end-to-end-application-development" ? (
                    <>
                        <p style={{ fontSize: 18, textAlign: 'justify', fontWeight: 600, marginTop: 5 }}>Our Process</p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>In-Depth Discovery and Planning:</span> We begin by conducting a thorough analysis of your business processes, requirements, and objectives. This initial phase involves close collaboration with stakeholders to define the project scope, create detailed specifications, and outline a strategic development roadmap. Our goal is to ensure that the final solution is perfectly aligned with your business goals. </p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>Custom Development: </span> Leveraging a variety of development frameworks, our team builds applications tailored to your unique business needs. We focus on creating high-performance solutions that integrate seamlessly with your existing systems, whether they are on-premises or cloud-based. Our approach ensures that the applications are scalable and can adapt to future business demands.</p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>Quality Assurance and Deployment:</span>Quality is at the core of our development process. Our dedicated QA team conducts extensive testing to ensure that the application is secure, performs optimally under load, and meets all specified requirements. We manage the deployment process meticulously, ensuring a smooth transition from development to production, with minimal disruption to your operations.</p>
                    </>
                ) : servicee.category === "chatbot-development" ? (
                    <>
                        <p style={{ fontSize: 18, textAlign: 'justify', fontWeight: 600, marginTop: 8 }}>Our Process</p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>Requirement Analysis and Strategy: </span>We begin by understanding your business objectives, target audience, and the specific functions your chatbot needs to perform. This phase involves defining use cases, designing conversation flows, and selecting the most appropriate natural language processing (NLP) models. We ensure that the chatbot is equipped to handle complex conversations and provide meaningful interactions.</p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>Design and Development: </span> Our development team builds the chatbot using advanced NLP frameworks, along with specialized platforms like Orbita and Amazon Connect for healthcare and patient support applications. The chatbot is then integrated with your existing systems to ensure it functions seamlessly within your business environment. We emphasize creating chatbots that are not only user-friendly but also context-aware, capable of delivering accurate and relevant responses.</p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>Testing and Optimization:</span>We conduct thorough testing to ensure the chatbot understands and responds accurately to user queries across various scenarios, languages, and accents. In healthcare applications, this also includes compliance with relevant regulations. Post deployment, we continuously monitor performance and make necessary adjustments to optimize the chatbot’s effectiveness and user satisfaction.</p>
                    </>
                ) : servicee.category === "ai-ml" ? (
                    <>
                        <p style={{ fontSize: 18, textAlign: 'justify', fontWeight: 600, marginTop: 8 }}>Our Process</p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>Discovery and Data Preparation:</span>We start by understanding your business challenges and identifying the areas where AI/ML can add the most value. This involves gathering and preparing data, which is crucial for training accurate and effective AI/ML models tailored to your needs.</p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>Model Development and Training:</span>Our team of data scientists employs cutting-edge algorithms and techniques to develop models that address your specific requirements. These models are trained on your data, optimizing them for both accuracy and performance, ensuring they deliver meaningful results in real-world applications.</p>
                        <p style={{ fontSize: 16, textAlign: 'justify', }}><span style={{ fontWeight: 'bold' }}>Deployment and Monitoring: </span> After thorough testing, the AI/ML models are deployed into production and integrated with your existing systems. We provide ongoing monitoring and fine-tuning to ensure the models continue to perform optimally, adapting to new data and evolving business needs.</p>

                    </>
                ) : (
                    <>
                        <h4 style={{ fontFamily: FONT.fontBold, textAlign: 'justify' }}>Technologies We Use:</h4>
                        <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify' }}><span style={{ fontFamily: FONT.fontBold }}>1. iOS App Development:</span> At Intellection Software, we possess the expertise to create fully-fledged, stable, and scalable iOS applications that meet your business needs. Our team specializes in the following technologies to ensure that your app is not only functional but also optimized for performance:</p>
                        <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify', marginTop: '-10px', marginLeft: '15px' }}><span style={{ fontFamily: FONT.fontBold }}>• Swift:</span> Leverage the power of Swift, Apple’s modern programming language, to build fast, secure, and high-performing iOS applications.</p>
                        <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify', marginTop: '-10px', marginLeft: '15px' }}><span style={{ fontFamily: FONT.fontBold }}>• Objective-C:</span> For projects requiring legacy support or complex integrations, our expertise in Objective-C ensures seamless compatibility and robust functionality.</p>
                        <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify', marginTop: '-10px', marginLeft: '15px' }}><span style={{ fontFamily: FONT.fontBold }}>• SwiftUI:</span> We utilize SwiftUI for building dynamic user interfaces that are both visually appealing and highly responsive across all Apple devices.</p>
                        <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify', marginTop: '-10px', marginLeft: '15px' }}><span style={{ fontFamily: FONT.fontBold }}>• CocoaPods:</span> Our use of CocoaPods allows for the efficient integration of third-party libraries, ensuring that your app is feature-rich and easy to maintain.</p>
                        <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify', }}><span style={{ fontFamily: FONT.fontBold }}>2. Android App Development:</span> We empower businesses to establish a strong presence on Android devices by developing apps that are both cutting-edge and user-friendly. Utilizing the latest technologies, our Android app development services include:</p>
                        <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify', marginTop: '-10px', marginLeft: '15px' }}><span style={{ fontFamily: FONT.fontBold }}>• Kotlin:</span> We use Kotlin, the preferred language for Android development, to create apps that are not only concise and efficient but also highly maintainable.</p>
                        <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify', marginTop: '-10px', marginLeft: '15px' }}><span style={{ fontFamily: FONT.fontBold }}>• Java:</span> With our deep knowledge of Java, we can build versatile Android applications that are compatible with a wide range of devices and OS versions.</p>
                        <p style={{ fontFamily: FONT.primaryFont, textAlign: 'justify', marginTop: '-10px', marginLeft: '15px' }}><span style={{ fontFamily: FONT.fontBold }}>• Gradle:</span> Our expertise in Gradle ensures that your Android apps are built with a robust and flexible build system, optimizing performance and speeding up the development process.</p>
                    </>
                )}
            </div>
        </>
    );
}

export default AI_MLServices;


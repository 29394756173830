import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';

import Chatbot from '../assets/images/software.png'
import Android from '../assets/images/smartphone.png'
import DataScience from '../assets/images/data-visualization.png'
import CAD from '../assets/images/video_processing.png'
import AI from '../assets/images/artificial-intelligence.png'
import VideoProcessing from '../assets/images/video_processing.png'
import OurServicesCardComponent from './OurServicesCardComponent'
import './Navv.css'
const Data = [
    // {
    //     id: 1,
    //     img: VideoProcessing,
    //     heading: 'Image/Video Processing  Solutions',
    //     para:'Empowering Enterprises with Advanced Image Processing Solutions'
    // },
    {
        id: 2,
        category:'end-to-end-application-development',
        img: Android,
        heading: 'End-To-End Enterprise Level Application Development',
        para:'Transforming ideas into scalable enterprise applications.'
    },
    
    {
        id: 3,
        category:'chatbot-development',
        img: Chatbot,
        heading: 'Conversational Chatbot Development',
        para:'Crafting chatbots for effortless customer engagement.'
    },
    {
        id: 4,
        category:'data-science',
        img: DataScience,
        heading: 'Data Science and Data Visualization',
        para:'Simplifying big data through impactful visual representation.'
    },
    {
        id: 5,
        category:'ai-ml',
        img: AI,
        heading: 'AI-ML Based Application Development',
        link: '/ai-ml-service',
        para:'Revolutionizing the future with AI/ML powered solutions'
    },
   
    {
        id: 6,
        category:'mobile-application',
        img: CAD,
        heading: 'Mobile Application Development',
        para:`Empowering growth with cutting-edge mobile applications.`
    },

];

const OurServices = () => {
    return (
        <>
            <div className="WhatWeDoComponent" style={{ marginTop: 35 }}>
                <h3 style={{ fontSize: 42, fontWeight: '600', fontFamily: FONT.primaryFont,textAlign:'center',color:COLORS.heading }}>Our Area Of <span style={{ color: 'rgba(111, 45, 168, 1)' }}>Expertise</span> </h3>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>
                    <Row style={{ justifyContent: 'center' }} >
                        {Data.map((item, index) => (
                            <OurServicesCardComponent item={item} md={4} index={index} border={100} />
                        ))}
                    </Row>
                </div>
            </div>

            {/* <div style={{ marginTop: '120px', paddingLeft:40,paddingRight:40 }}>
                <p style={{ color: COLORS.heading, fontSize: 34, fontFamily: 'IstokWeb', fontWeight: 'bold', fontFamily: FONT.primaryFont, }}>Our Services</p>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>
                    <Row style={{ justifyContent: 'center' }}>
                        {Data.map((item, index) => (
                            <OurServicesCardComponent item={item} md={4} index={index} border={100} />
                        ))}
                    </Row>
                </div>
            </div> */}
        </>
    )
}
export default OurServices
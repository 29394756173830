import React from 'react';
import { Col } from 'react-bootstrap';
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';

const TechStackCardComponent = ({ item, index }) => {
    return (
        <Col lg={4} md={6} sx={12} key={index} className="d-flex">
            <div className="custom-container">
                <img src={item.img} width={50} height={50} alt="Item" />
                <p className="item-title" style={{ fontFamily: FONT.fontBold }}>
                    {item.title}
                </p>
                <ul className="item-list" style={{ fontFamily: FONT.primaryFont }}>
                    {item.list.map((listItem, listIndex) => {
                        // Split the text into the label and the rest of the content
                        const [label, ...value] = listItem.split(':');
                        return (
                            <li key={listIndex}>
                                <span style={{fontFamily:FONT.primaryFont }}>{label}</span>
                                <span style={{fontFamily:FONT.primaryFont}}>{value.join(':')}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Col>
    );
};

export default TechStackCardComponent;

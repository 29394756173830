import React from 'react';
import TopNavbar from './Components/TopNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutUs from './Pages/AboutUs';
import Solutions from './Pages/Services';
import Footer from './Components/Footer';
import OurWork from './Pages/OurWork';
import OurProducts from './Pages/OurProducts';
import Career from './Pages/Career';
import WhyChooseUsDetailPage from './Components/WhyChooseUsDetailPage';
import ContactUs from './Pages/ContactUs';
import AI_MLServices from './Components/AI_MLServices';
import OurProjectDetailPage from './Components/OurProjectDetailPage';
import OurProductDetailPage from './Components/OurProductDetailPage';

import Services from './Pages/Services';
import OurLeadership from './Components/OurLeadership';
import AboutUsMore from './Components/AboutUsMore';
import NotFound from './Components/NotFound';
import WhatWeDoNew from './Components/WhatWeDoNew';
import Testimonial from './Components/Testimonial';
import IelectionDetailPage from './Components/IelectionDetailPage';
import LinkedInTracking from './Components/LinkedInTracking';

function App() {
  return (
    <div className="App">
      <Router>
      <LinkedInTracking />
        {/* <TopNavbar />
        <ScrollToTop /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/leadership" element={<OurLeadership />} />
          <Route path="/services" element={<Services />} />
          <Route path="/company-overview" element={<AboutUs />} />
          <Route path="/work" element={<OurWork />} />
          <Route path="/products" element={<OurProducts />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/services/:category" element={<AI_MLServices />} />
          <Route path="/project-details" element={<OurProjectDetailPage />} />
          <Route path="/product-details" element={<OurProductDetailPage />} />
          <Route path="/iElection-details" element={<IelectionDetailPage />} />
          <Route path="/about" element={<AboutUsMore />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/o" element={<WhatWeDoNew />} />
          {/* <Route path="/what-we-do/:category" element={<WhatWeDoNew />} /> */}
          <Route path="/what-we-do/:category" element={<WhyChooseUsDetailPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

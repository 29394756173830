import React from 'react'
import { Col, Container, Row, Card, Button } from 'react-bootstrap'
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';
import REVAProject from '../assets/images/REVAProject.png';
import Navv from './Navv'
import Form from 'react-bootstrap/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';

const OurProjectDetailPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const project = location.state.project;
    console.log(project);
    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
        <Helmet>
                <title>Intellection: Our Products</title>
                <meta name="description" content="Intellection offers a wide range of innovative software solutions including web development, mobile app development, AI/ML services, chatbot development, and custom software tailored to business needs." />
                <meta name="keywords" content="Web Development, Mobile App Development, Chatbot Development, Data Science, Data Visualization, AI/ML, Media & Entertainment, Healthcare" />
                <meta name="robots" content="index, follow" />
                {/* Open Graph tags for social sharing */}
                <meta property="og:title" content="Innovative Solutions for Your Business" />
                <meta property="og:description" content="Explore our innovative services and solutions for industries across the globe. We deliver expertise in various sectors." />
                {/* <meta property="og:image" content="https://www.yourwebsite.com/path-to-your-image.jpg" /> */}
                <meta property="og:url" content="https://www.intellection.in/company-overview" />
                <meta property="og:type" content="website" />
                {/* <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home - Innovative Solutions for Your Business" />
                <meta name="twitter:description" content="Explore our innovative services and solutions for industries across the globe." />
                <meta name="twitter:image" content="https://www.yourwebsite.com/path-to-your-image.jpg" /> */}
            </Helmet>
            <NavBar />

            <div className='WhatWeDoComponent' style={{ marginTop: '100px', marginBottom: '100px' }}>
                <button style={{ border: 'none', backgroundColor: 'white' }} onClick={handleBackClick}>
                    <FaArrowLeftLong style={{ marginRight: '8px', color: '#6F2DA8', fontSize: '30px' }} />
                </button>

                <p style={{ color: COLORS.heading, fontSize: 34, fontWeight: 'bold', fontFamily: FONT.fontBold, }}>{project.heading}</p>

                <Row>
                    <Col lg={8} md={12}>
                        <p style={{ textAlign: 'justify', width: '95%', fontFamily: FONT.primaryFont, }}>{project.more}</p>
                        <p style={{ textAlign: 'justify', marginTop: 5, width: '95%', fontFamily: FONT.primaryFont, }}>{project.more1}</p>
                        {project.heading === "BARC India: Census Data Processing for Towncode Generation" ?
                            <p style={{ textAlign: 'justify', marginTop: 20, width: '95%', fontFamily: FONT.primaryFont, }}>{project.objective1}</p> : null}
                    </Col>
                    <Col >
                        <img src={project.img} alt="Our Product" className='img-fluid'  width={project.heading === "BARC INDIA: Television Audience Measurement Projects" || project.heading === "BARC India: Household Recruitment Application" ? 350 :400}  />

                    </Col>
                </Row>
                {project.heading === "BARC India: Census Data Processing for Towncode Generation" ? null :
                    <p style={{ textAlign: 'justify', marginTop: 20, width: '95%', fontFamily: FONT.primaryFont, }}>{project.objective1}</p>}
                <p style={{ textAlign: 'justify', marginTop: 10, width: '95%', fontFamily: FONT.primaryFont, }}>{project.objective2}</p>
            </div>
        </>
    )
}
export default OurProjectDetailPage
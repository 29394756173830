import React,{useState} from 'react'
import { Col, Container, Row, Button, Card,Toast } from 'react-bootstrap'
import { COLORS } from '../utils/Colors'
import { FONT } from '../utils/Font'
import email from '../assets/images/gmail.png'
import Form from 'react-bootstrap/Form';
import phone from '../assets/images/phone.png'
import ReCAPTCHA from 'react-google-recaptcha';
import './NavBar.css'
const Contact = () => {
    const handleRecaptchaChange = (value) => {
        console.log('Captcha value:', value);
    };
    const [showToast, setShowToast] = useState(false); // State for controlling Toast visibility
    const [toastMessage, setToastMessage] = useState(''); // State for Toast message
    const [isSuccess, setIsSuccess] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        description: ''
    });
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
    
        try {
            const response = await fetch('https://intellectionweb.in/form_api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            console.log(response); // Log the response to see if it's hitting this block
    
            if (response.ok) {
                // Success handling
                setIsSuccess(true);
                setToastMessage('Form submitted successfully!');
                setShowToast(true);
    
                setFormData({
                    name: '',
                    email: '',
                    description: ''
                });
            } else {
                // Error handling
                setIsSuccess(false);
                setToastMessage('Failed to submit the form.');
                setShowToast(true);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setIsSuccess(false);
            setToastMessage('An error occurred. Please try again.');
            setShowToast(true);
        }
    };
    

    return (
        <>
            <div className='WhatWeDoComponent' style={{ marginTop: '100px', marginBottom: 100 }}>
                <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ color: COLORS.heading, fontFamily: FONT.fontBold, fontSize: 42, fontWeight: 'bold' }}>Connect <span style={{ color: 'rgba(111, 45, 168, 1)' }}>With Us </span> </p>
                    <p style={{ fontFamily: FONT.primaryFont, marginTop: '-18px' }}>We are here to provide you solutions</p>
                    <Col md={5}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '70px' }}>
                            <div style={{
                                backgroundColor: 'rgba(246, 247, 248, 1)',
                                borderRadius: '50%',
                                padding: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: '10px'
                            }}>
                                <img src={phone} style={{ color: 'rgba(111, 45, 168, 1)' }} />
                            </div>
                            <div>
                                <h5 style={{ fontWeight: 'bold',textAlign:"left",fontFamily: FONT.fontBold, }}>Phone</h5>
                                <h4>
                                    <a href="tel:+918788025946" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 8788025946
                                    </a>
                                </h4>
                            </div>

                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '70px' }}>
                            <div style={{
                                backgroundColor: 'rgba(246, 247, 248, 1)',
                                borderRadius: '50%',
                                padding: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: '10px'
                            }}>
                                <img src={email} alt='email' style={{ color: 'rgba(111, 45, 168, 1)' }} />
                            </div>
                            <div>
                                <h5 style={{ fontWeight: 'bold',textAlign:"left",fontFamily: FONT.fontBold, }}>Email</h5>
                                <a href="mailto:contact@intellection.in">
                                    <h4>contact@intellection.in</h4>
                                </a>

                            </div>
                        </div>
                    </Col>

                    <Col md={7} >
                        <Card
                            className='contactcard'
                            style={{
                                borderRadius: '5px',
                                marginTop: '20px',
                                boxShadow: '0px 3.13px 7.5px rgba(0, 0, 0, 0.12)',
                            }}
                        >
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Label style={{ color: 'rgba(0, 0, 0, 1)', fontWeight: 'bold' }}>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Your Name"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Label style={{ color: 'rgba(0, 0, 0, 1)', fontWeight: 'bold' }}>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter Your Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId="description">
                                        <Form.Label style={{ color: 'rgba(0, 0, 0, 1)', fontWeight: 'bold' }}>Your Requirement in Brief</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Enter Project Description"
                                            rows={5}
                                            value={formData.description}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Row>
                                <Button
                                    style={{
                                        backgroundColor: 'rgba(111, 45, 168, 1)',
                                        border: '1px solid rgba(111, 45, 168, 1)',
                                    }}
                                    type="submit"
                                    className="submit-button"
                                >
                                    Submit
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                </Row>


                {/* https://intellectionweb.in/form_api.php */}


            </div>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1000,
                    backgroundColor: isSuccess ? 'green' : 'red',
                    color: 'white',
                }}
            >
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </>
    )
}
export default Contact